html{
  height: 100%;
}
body {
  font-family: 'Heebo', sans-serif;
  font-size: 36px;
  margin: 0px;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.slick-slide {
  margin: 0 20px;
  outline: none;
}
.slick-slide div {
  outline: none;
}

a {
  text-decoration: none;
  color: #0275b8;
}
audio {
  outline: none;
}
video {
  outline: none;
}
ul {
  /* display: flex;
  list-style: none; */
}

@media only screen and (max-width: 600px) {
  body {
    font-size: 28px;
  }
  .react-multiple-carousel__arrow {
    width: 60px;
    height: 60px;
  }
}
